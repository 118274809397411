.upwork {
  background: url("https://api.iconify.design/simple-icons:upwork.svg?color=white")
    no-repeat center center / contain;
  content: url("https://api.iconify.design/simple-icons:upwork.svg?color=white&height=32");
  vertical-align: -0.125em;

  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: xxx-large;
}

.upwork:hover {
  background: url("https://api.iconify.design/simple-icons:upwork.svg?color=%230488a8")
    no-repeat center center / contain;
  content: url("https://api.iconify.design/simple-icons:upwork.svg?color=%230488a8&height=32");
  vertical-align: -0.125em;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: xxx-large;
}
